




























import { defineComponent, reactive, ref } from "@vue/composition-api";
import useNotifications from "@/use/notifications";

export default defineComponent({
  setup(props, { root }) {
    const { ensurePermissions } = useNotifications({ root });

    const form = ref<any>(null);

    const state = reactive({
      loading: false,
      isFormValid: true,
      error: false,
      showPassword: false,
    });

    const model = reactive({
      email: "",
      password: "",
    });

    const rules = {
      email: [(v: string) => !!v || "Podaj adres email"],
      password: [(v: string) => !!v || "Wpisz hasło"],
    };

    const onSubmit = async () => {
      await form.value.validate();

      if (state.isFormValid) {
        state.loading = true;

        root.$store
          .dispatch("user/login", {
            email: model.email,
            password: model.password,
          })
          .then(() => {
            state.error = false;
            root.$router.push(
              (root.$route.query as { next: string }).next || { name: "panel" }
            );

            ensurePermissions();
          })
          .catch(() => {
            state.error = true;
            setTimeout(() => (state.error = false), 1000);
          })
          .finally(() => (state.loading = false));
      }
    };

    return { form, state, model, rules, onSubmit };
  },
});
